export const isValidEmail = (email: string) => {
  const emailFormatRegex = /^[^@]+@[^@]+\.[^@]+$/;
  const isValid = emailFormatRegex.test(email);
  return isValid;
};

export const isNumber = (input: string) => {
  if (input === '') {
    return false;
  }
  return /^(\s*|\d+)$/.test(input);
};

export const isValidPassword = (password: string) => {
  const passwordFormatRegex = /^.{6,}$/;
  const isValid = passwordFormatRegex.test(password);
  return isValid;
};

export const isValidNewPassword = (password: string) => {
  const passwordFormatRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d@$!%*?&-]).{8,}$/;
  const isValid = passwordFormatRegex.test(password);
  return isValid;
};

export const isValidNumericRange = (value: string, range: string | undefined, type: 'int' | 'float' = 'int') => {
  if(!range) {
    return false;
  }

  if(type === 'int') {
    if(!isNumber(value)) {
      return false;
    }
  } else if(type === 'float') {
    if(!isValidFloatNumber(value)) {
      return false;
    }
  }
  
  const number = parseFloat(value);
  const rangeSplit = range.split('-');
  if(rangeSplit.length !== 2){
    return false;
  }
  
  const lowerLimitString = rangeSplit[0];
  const upperLimitString = rangeSplit[1];
  if(!isNumber(lowerLimitString) || !isNumber(upperLimitString)) {
    return false;
  }

  const lowerLimit = parseFloat(lowerLimitString);
  const upperLimit = parseFloat(upperLimitString);
  if(number > upperLimit || number < lowerLimit){
    return false;
  }

  return true;
}


export const isValidMaxLength = (value: string, maxLength: string | undefined) => {
  if(!maxLength || !isNumber(maxLength)) {
    return false;
  }

  const lengthLimit = parseFloat(maxLength);
  if(value.length > lengthLimit) {
    return false;
  }

  return true;
}

export const isValidFloatNumber = (value: string) => {
  const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (value === '') {
    return false;
  }
  return floatRegex.test(value);
}
