import React, { useEffect, useState } from 'react';
import Layout from '../../shared/Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../index';
import './Results.scss';
import localization from '../../../localization/localization';
import { getResultPDFAjax, getPatientPDFsAjax } from '../../../helpers/httpService';
import { showMessage } from '../../../store/actions/messageBarActions';
import InfoModal from '../../shared/InfoModal/InfoModal';
import ResultTable, { ResultTableRow } from '../../shared/ResultTable/ResultTable';
import { safeLocalization } from '../../../helpers/localizationHelper';

const Results = () => {
  const language = useSelector((state: RootState) => state.localization.language);
  const dispatch = useDispatch();

  const [openInfoModalType, setOpenInfoModalType] = useState<'medicalAdvice' | 'networksInfo' | 'loading' | 'hidden' | null>(null);
  const [selectedStudyId, setSelectedStudyId] = useState<string | null>(null);
  const [selectedreportType, setSelectedReportType] = useState<string | null>(null);

  const [resultRows, setResultRows] = useState(new Map<string, ResultTableRow[]>());
 
  useEffect(() => {
    getPatientPDFsAjax()
      .then(res => {
        res.data.forEach( row => {
          row.reportTypes.forEach(type => {
            const date = new Date(row.date);
            const year = date.getFullYear().toString();

            const tableRow: ResultTableRow = { 
              studyId: row.studyId, 
              date: new Date(row.date), 
              reportType: type,
              handleDownloadClick: handleButtonClick
            };

            if(resultRows.has(year)) {
              
              const currentRows = resultRows.get(year) || [];
              currentRows.push(tableRow)
              resultRows.set(year, currentRows)
            } else {
              resultRows.set(year, [tableRow])
            }
          })
        });
        setResultRows(resultRows);
        setOpenInfoModalType('hidden');
      })
      .catch(err => {
        console.log(err.message);
        dispatch(showMessage(localization.failedToFetchTestResults[language]));
      });
  }, []);

  const base64ToPdfBlob = (data: string) => {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf' });
  };

  const handleOpenClick = (studyId: string, reportType: string) => {
    setOpenInfoModalType('loading');
    getResultPDFAjax(studyId, reportType)
      .then( res => {
        console.log(res);
        setOpenInfoModalType(null);
        const blob = base64ToPdfBlob(res.data.fileContents);
        // @ts-expect-error:
        if (window.navigator.msSaveOrOpenBlob) {
          // @ts-expect-error:
          window.navigator.msSaveOrOpenBlob(blob, 'result.pdf');
        } else {
          const fileUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = fileUrl;
          a.download = 'MYndspanAssesment' + studyId +  safeLocalization(reportType, language, true) + '.pdf';
          document.body.appendChild(a);
          a.click();
        }
      })
      .catch(err => {
        console.log(err.message);
        dispatch(showMessage(localization.resultDownloadFailed[language]));
        setOpenInfoModalType(null);
      });
  };

  const handleButtonClick = (id: string, reportType: string) => {
    setSelectedStudyId(id);
    setSelectedReportType(reportType);
    setOpenInfoModalType('medicalAdvice');
  }

  const handlelastModalClick = () => {
    setOpenInfoModalType(null);
    handleOpenClick(selectedStudyId || '', selectedreportType || '')
  }

  const render = (): JSX.Element => {
    let infoModal = null;
    if(openInfoModalType === 'medicalAdvice') {
      infoModal = 
        <InfoModal 
          type='medicalAdvice' 
          handleContinueClick={() => {setOpenInfoModalType('networksInfo')}} 
          handleCloseClick={() => {setOpenInfoModalType(null)}}
        />
    } else if(openInfoModalType === 'networksInfo') {
      infoModal = 
        <InfoModal 
          type='networksInfo' 
          handleContinueClick={() => {handlelastModalClick()}} 
          handleCloseClick={() => {setOpenInfoModalType(null)}}
        />
    } else if(openInfoModalType === 'loading') {
      infoModal = 
      <InfoModal 
        type='loading' 
        handleContinueClick={() => {}} 
        handleCloseClick={() => {}}
      />
    }

    const resultTables: JSX.Element[] = [];
    const tableKeys = Array.from(resultRows.keys());
    tableKeys.sort((first, second) => first > second ? -1 : 1);

    tableKeys.forEach(key => {
      const tableRow = resultRows.get(key);
      if(tableRow) {
        const table = <ResultTable 
          year={key}
          rows={tableRow}
          key={key}
        />
        resultTables.push(table);
      }
    })

    return (
      <div className="results">
        <div className="results__content flex-column">
          <span className="results__header">{localization.myReports[language]}</span>
          {resultTables}
        </div> 
        {infoModal}
      </div>
    );
  };
  return (
    <Layout render={render}/>
  );
};

export default Results;
