import React from 'react';
import './Layout.scss';
import Header from '../Header/Header';
import Snackbar from '../MessageBar/MessageBar';

interface LayoutProps {
  render: () => JSX.Element
}

const Layout = (props: LayoutProps) => {
  return (
    <div className="layout">
      <Header/>

      <div>
        {props.render()}
      </div>

      <Snackbar/>
    </div>
  );
};

export default Layout;

