import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getSessionDataAjax } from './httpService';
import { useDispatch } from 'react-redux';
import { setUserLogIn, cleanStore } from '../store/actions/userActions';
import { storePatientData } from '../store/actions/patientActions';

interface CheckAuthParams {
  path: string;
}

const CheckAuth = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { path } = useParams<CheckAuthParams>();

  useEffect(() => {

    getSessionDataAjax()
      .then(res => {

        console.log(res);
        console.log(res.data)
        dispatch(storePatientData(res.data));
        dispatch(setUserLogIn());

        history.push('/' + path);
      })
      .catch(err => {
        console.log(err);
        if (err.response?.status === 401) {
          dispatch<any>(cleanStore());
        }
        console.log(err.message);
        history.push('/');
      });

  }, [dispatch, path, history]);


  return (
    <div></div>
  );
};

export default CheckAuth;
