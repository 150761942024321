import * as actionTypes from '../actions/actionTypes';
import { MessageBarState, SnackbarActions } from '../types';

const initialState: MessageBarState = {
  message: '',
  messageType: 'info'
};

export const messageBarReducer = ( state = initialState, action: SnackbarActions): MessageBarState => {
  switch (action.type) {

  case actionTypes.SHOW_MESSAGE_BAR:

    return action.payload;

  case actionTypes.HIDE_MESSAGE_BAR:

    return initialState;

  default:

    return state;
  }
};
