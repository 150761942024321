
const dictionay = {
  quit: {
    en: 'Quit',
    fi: 'Lopeta'
  },
  birthday: {
    en: 'Date of birth',
    fi: 'Syntymäpäivä'
  },
  invalidDate: {
    en: 'Invalid date',
    fi: 'Virheellinen päivä'
  },
  logIn: {
    en: 'Log in',
    fi: 'Kirjaudu sisään'
  },
  logOut: {
    en: 'Log out',
    fi: 'Kirjaudu ulos'
  },
  failedToLogIn: {
    en: 'Failed to log in',
    fi: 'Kirjautuminen epäonnistui'
  },
  email: {
    en: 'Email address',
    fi: 'Sähköposti'
  },
  password: {
    en: 'Password',
    fi: 'Salasana'
  },
  retypePassword: {
    en: 'Retype Password',
    fi: 'Salasana uudelleen'
  },
  forgotPassword: {
    en: 'Forgot password?',
    fi: 'Unohditko salasanasi?'
  },
  accountDetails: {
    en: 'Account details',
    fi: 'Tilin tiedot'
  },
  myAccount: {
    en: 'My account',
    fi: 'Tilini'
  },
  firstName: {
    en: 'First name',
    fi: 'Etunimi'
  },
  lastName: {
    en: 'Last name',
    fi: 'Sukunimi'
  },
  currentPassword: {
    en: 'Current password',
    fi: 'Nykyinen salasanasi'
  },
  newPassword: {
    en: 'New password',
    fi: 'Uusi salasanasi'
  },
  repeatNewPassword: {
    en: 'Repeat new password',
    fi: 'Toista uusi salasanasi'
  },
  updateAccount: {
    en: 'Update account',
    fi: 'Päivitä tili'
  },
  repeatChangedEmail: {
    en: 'Repeat changed email',
    fi: 'Toista muuttunut sähköposti'
  },
  emailsDontMatch: {
    en: 'Emails don\'t match',
    fi: 'Sähköpostit eivät vastaa toisiaan'
  },
  passwordsDontMatch: {
    en: 'New password and repeat new password don\'t match',
    fi: 'uusi salasana ja toista uusi salasana eivät vastaa toisiaan'
  },
  accountDetailsChanged: {
    en: 'Account details changed succesfully',
    fi: 'Tilin tiedot päivitetty onnistuneesti'
  },
  failedToChangeAccountDetails: {
    en: 'failed to change account details',
    fi: 'Tilin tietojen vaihtaminen epäonnistui'
  },
  account: {
    en: 'Account',
    fi: 'Tili'
  },
  unknownError: {
    en: 'Unknown error happended',
    fi: 'Odottamaton virhe tapahtui'
  },
  previous: {
    en: 'Previous',
    fi: 'Edellinen'
  },
  cancel: {
    en: 'Cancel',
    fi: 'Peruuta'
  },
  set: {
    en: 'Set',
    fi: 'Aseta'
  },
  name: {
    en: 'Name',
    fi: 'Nimi'
  },
  team: {
    en: 'Team',
    fi: 'Joukkue'
  },
  previousBrainImaging: {
    en: 'Previous brain imaging',
    fi: 'Edellinen aivojen magneettikuvaus'
  },
  timeSinceIncident: {
    en: 'Time since the incident',
    fi: 'Tapaturmasta kulunut aika'
  },
  lostConciousness: {
    en: 'Lost conciousness during the incident',
    fi: 'Menetin tajuntani tapahtuman aikana'
  },
  symptoms: {
    en: 'Symptoms',
    fi: 'Oireet'
  },
  drankCoffee: {
    en: 'Drank coffee or energy drink during the last 12 hours',
    fi: 'Joitko kahvia tai energiajuomaa 12 tunnin sisällä'
  },
  drankAlcohol: {
    en: 'Drank alcohol during the last 12 hours',
    fi: 'Joitko alkoholia viimeisen 12 tunnin aikana'
  },
  drinkAmount: {
    en: 'Drank amount',
    fi: 'Juotu määrä'
  },
  timeOfUnconsciousness: {
    en: 'Time of unconsciousness',
    fi: 'Aika tajuttomana'
  },
  failedToLogOut: {
    en: 'Failed to log out',
    fi: 'Uloskirjautuminen epäonnistui'
  },
  fieldMustBeNumber: {
    en: 'Field must be a number',
    fi: 'Kentän täytyy olla numero'
  },
  invalidEmailOrPassword: {
    en: 'Invalid email or password',
    fi: 'Virheellinen sähköposti tai salasana'
  },
  dateFormat: {
    en: 'In format DD.MM.YYYY',
    fi: 'Muodossa PP.KK.VVVV'
  },
  firstPet: {
    en: 'Your first pet',
    fi: 'Ensimmäinen lemmikkisi'
  },
  favouriteFood: {
    en: 'Your fovourite food',
    fi: 'Lempi ruokasi'
  },
  info: {
    en: 'Info',
    fi: 'Info'
  },
  Inwhatcitywereyouborn: {
    en: 'In what city were you born?',
    fi: 'Missä kaupungissa synnyit?'
  },
  Whatwasyourchildhoodnickname: {
    en: 'What was your childhood nickname?',
    fi: 'Mikä oli lapsuutesi lempinimi?'
  },
  Whatstreetdidyouliveoninthirdgrade: {
    en: 'What street did you live on in third grade?',
    fi: 'Millä kadulla asuit kolmannella luokalla?'
  },
  Whatisyouroldestsiblingsmiddlename: {
    en: 'What is your oldest sibling\'s middle name?',
    fi: 'Mikä on vanhimman sisaruksesi toinen etunimi'
  },
  Whatwasthenameofyourfirststuffedanimal: {
    en: 'What was the name of your first stuffed animal?',
    fi: 'Mikä oli sinun ensimmäisen pehmolelun nimi?'
  },
  Whatisyourmaternalgrandmothersmaidenname: {
    en: 'What is your maternal grandmother\'s maiden name?',
    fi: 'Mikä on äitisi isoäidin tyttönimi?'
  },
  Whatisthenameofyourfavoritechildhoodteacher: {
    en: 'What is the name of your favorite childhood teacher?',
    fi: 'Mikä on lapsuutesi lempi opettajan nimi'
  },
  Whatwasthemakeandmodelofyourfirstcar: {
    en: 'What was the make and model of your first car?',
    fi: 'Mikä oli ensimmäisen autosi merkki ja malli?'
  },
  ForgotPasswordInstruction: {
    en: 'Please enter your email address for reseting your password',
    fi: 'Syötä sähköpostiosoitteesi salasanan uusimista varten'
  },
  SendPasswordResetEmail: {
    en: 'Send password reset email',
    fi: 'Lähetä sähkposti salasanan uusimiseksi'
  },
  PasswordResetLinkInvalid: {
    en: 'Password reset link is invalid',
    fi: 'Salasananpalautuslinkki on virheellinen'
  },
  changePassword: {
    en: 'Change password',
    fi: 'Vaihda salasana'
  },
  passwordHasBeenReset: {
    en: 'Your password has been reset',
    fi: 'Salasanasi on vaihdettu'
  },
  checkYourEmailForPasswordResetLinkStart: {
    en: 'If the given e-mail ',
    fi: 'Jos annettu sähköpostiosoite '
  },
  checkYourEmailForPasswordResetLinkEnd: {
    en: ' exists in the system the password reset instructions have been sent to the e-mail',
    fi: ' on systeemissämme, lähetimme siihen sähköpostinpalautusviestin'
  },
  invalidEmailFormat: {
    en: 'Email is not in valid format',
    fi: 'Sähköposti ei ole oikeassa formaatissa'
  },
  typeCurrentPassword: {
    en: 'Type your current password here if you are changing your password',
    fi: 'Kirjoita nykyinen salasanansi tähän jos olet vaihtamassa salasanaasi'
  },
  typeNewPassword: {
    en: 'Type your new password here if you are changing your password',
    fi: 'Kirjoita tähän uusi salasanasi jos olet vaihtamassa salasanaasi'
  },
  reTypeNewPassword: {
    en: 'Retype your new password here if you are changing your password',
    fi: 'Uudelleenkirjoita tähän uusi salasanasi jos olet vaihtamassa salasanaasi'
  },
  repeatEmailIfChanging: {
    en: 'Retype your new email here if you are changing your email',
    fi: 'Toista sähköpostisi tähän, jos olet vaihtamassa sähköpostia'
  },
  yourAnswer: {
    en: 'Your answer',
    fi: 'Vastaukesi'
  },
  ifChangingEmail: {
    en: 'You changed your email only to Email field, if you are changing repeat your email to Repeat Email field also',
    fi: 'vaihdot sähköpostia Sähköpostikenttään, jos olet vaihtamassa sähköpostia toista se Toista sähköposti - kenttään?'
  },
  typePasswordError: {
    en: 'Type your new password',
    fi: 'Kirjoita uusi salasanasi'
  },
  currentPasswordCantBeEmpty: {
    en: 'Current password can\'t be empty when you are changing password',
    fi: 'Nykyinen salasana ei voi olla tyhjä kun olet vaihtamassa salasanaa'
  },
  id: {
    en: 'ID',
    fi: 'Tunniste'
  },
  date: {
    en: 'Date',
    fi: 'Päivämäärä'
  },
  place: {
    en: 'Place',
    fi: 'Paikka'
  },
  testResults: {
    en: 'Test results',
    fi: 'Testitulokset'
  },
  failedToFetchTestResults: {
    en: 'Failed to fetch test results',
    fi: 'Testitulosten hakeminen epäonnistui'
  },
  resultDownloadFailed: {
    en: 'Failed to download the result',
    fi: 'Tuloksen lataus epäonnistui'
  },
  results: {
    en: 'Results',
    fi: 'Tulokset'
  },
  fieldIsRequired: {
    en: 'Field cannot be empty',
    fi: 'Kenttä ei voi olla tyhjä'
  },
  emailError: {
    en: 'Email is in invalid format',
    fi: 'Sähköposti on väärässä muodossa'
  },
  passwordError: {
    en: 'Password does not meet the requirements. Password must contain at least 8 characters, one CAPITAL letter and one number or special character',
    fi: 'Salasana ei täytä vaatimuksia'
  },
  someFielsAreInvalid: {
    en: 'Some fields are invalid, check them and continue after that',
    fi: 'Jotkut kentät ovat täytetty virheellisesti, tarkista ne ja jatka sen jälkeen seuraavalle sivulle'
  },
  repeatPassword: {
    en: 'Repeat password',
    fi: 'Toista salasana'
  },
  returnToLoginPage: {
    en: 'Back to login',
    fi: 'Paluu kirjautumisikkunaan'
  },
  rememberLogin: {
    en: 'Remember your password?',
    fi: 'Muistatko salasanasi?'
  },
  GiveNewPassword: {
    en: 'Please input a new password',
    fi: 'Anna uusi salasana'
  },
  PasswordReset: {
    en: 'Password Reset',
    fi: 'salasanan palautus'
  },
  passwordFormat: {
    en: 'Must have at least 8 characters and contain at least one CAPITAL letter and one number or a special character.',
    fi: 'Salasanassa tulee olla vähintään 8 merkkiä, joista vähintään yksi on ISO kirjain ja numero tai erikoismerkki'
  },
  EmailFormat: {
    en: 'Enter email in example@gmail.com format',
    fi: 'Syötä sähköposti muodossa: esimerkki@gmail.com'
  },
  medicalAdviceModalPart1: {
    en: 'This report is not intended to be used to identify the presence or absence of a clinical diagnosis.',
    fi: ''
  },
  medicalAdviceModalPart2: {
    en: 'MYndspan does not provide medical advice.',
    fi: ''
  },
  medicalAdviceModalPart3: {
    en: 'The content of this report is not intended to substitute professional medical advice. Please contact your physician with any questions or concerns.',
    fi: ''
  },
  acceptAndContinue: {
    en: 'Accept and continue',
    fi: ''
  },
  networksInfoPart1: {
    en: 'How active your networks were during the time of the scan is completely individual to you — every brain and the activity it produces is completely unique!',
    fi: ''
  },
  networksInfoPart2: {
    en: 'The analysis in our reports at times display your activity against our small normative dataset for interest and visualisation purposes.',
    fi: ''
  },
  networksInfoPart3: {
    en: 'Where you sit in comparison to this small dataset is neither a good nor bad thing.',
    fi: ''
  },
  networksInfoPart4: {
    en: 'The most informative way to use MYndspan reports is by monitoring changes in your own activity over time.',
    fi: ''
  },
  close: {
    en: 'Close',
    fi: ''
  },
  loadingreport: {
    en: 'Loading report. Please wait',
    fi: ''
  },
  personalInfo: {
    en: 'Personal Info',
    fi: ''
  },
  legalName: {
    en: 'Legal name',
    fi: ''
  },
  day: {
    en: 'Day',
    fi: ''
  },
  month: {
    en: 'Month',
    fi: ''
  },
  year: {
    en: 'Year',
    fi: ''
  },
  repeatEmail: {
    en: 'Repeat email',
    fi: ''
  },
  myReports: {
    en: 'My Reports',
    fi: ''
  },
  download: {
    en: 'Download',
    fi: ''
  },
  report: {
    en: 'Report',
    fi: ''
  },
  baseline: {
    en: 'Baseline',
    fi: ''
  },
  longitudinal: {
    en: 'Monitor',
    fi: ''
  },
  unknown: {
    en: 'Unknown',
    fi: ''
  },
  invalidNumericRange: {
    en: 'Invalid value. Valid range is',
    fi: ''
  },
  invalidValidMaxLength: {
    en: 'Maximum length exceeded',
    fi: ''
  },
  save: {
    en: 'Save',
    fi: ''
  },
  failedToUpdatePersonalInfo: {
    en: 'Failed to update personal info',
    fi: ''
  },
  personalInfoUpdated: {
    en: 'Personal info updated succesfully',
    fi: ''
  },
  edit: {
    en: 'Edit',
    fi: ''
  },
  exploreResults: {
    en: 'Explore your results.',
    fi: ''
  },
  learMoreBrain: {
    en: 'Learn more about your unique brain activity',
    fi: ''
  },
  sleepAndNutritionInfo: {
    en: 'Assess the impact of your sleep and nutrition habits on your brain connectivity',
    fi: ''
  },
  cognitiveInfo: {
    en: 'Monitor your cognitive function',
    fi: ''
  },
  monday: {
    en: 'Mon',
    fi: ''
  },
  thuesday: {
    en: 'Tue',
    fi: ''
  },
  wednesday: {
    en: 'Wed',
    fi: ''
  },
  thursday: {
    en: 'Thu',
    fi: ''
  },
  friday: {
    en: 'Fri',
    fi: ''
  },
  saturday: {
    en: 'Sat',
    fi: ''
  },
  sunday: {
    en: 'Sun',
    fi: ''
  },
  failedToChangePassword: {
    en: 'Failed to change the password',
    fi: ''
  }
};

export default dictionay;
