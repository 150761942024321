import React from 'react';
import Layout from '../../shared/Layout/Layout';
import './NotificationPage.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../index';
import localization from '../../../localization/localization';
import Button from '../../shared/Button/Button';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import questionmarkIcon from '../../../imgs/question-icon.png';
import doneIcon from '../../../imgs/done-icon.png';

interface NotificationPageProps {
  type: string;
}

interface NotificationPageParams {
  email: string;
}

const NotificationPage = (props: NotificationPageProps) => {
  const history = useHistory();
  const { email } = useParams<NotificationPageParams>();

  let notificationText = <div />;
  const language = useSelector((state: RootState) => state.localization.language);

  const infoIcon = props.type === 'pass-has-been-reset'
    ? doneIcon
    : questionmarkIcon;

  if (props.type === 'invalid-pass-reset-link') {
    notificationText = <span className="notification__info-text">{localization.PasswordResetLinkInvalid[language]}</span>;
  }
  else if (props.type === 'check-email-for-pass-reset') {
    notificationText = (
      <span className="notification__info-text">
        {localization.checkYourEmailForPasswordResetLinkStart[language]}
        <b> {email} </b>
        {localization.checkYourEmailForPasswordResetLinkEnd[language]}
      </span>
    );
  }
  else if (props.type === 'pass-has-been-reset') {
    notificationText = <span className="notification__info-text">{localization.passwordHasBeenReset[language]}</span>;
  }

  const handleButtonClick = (): void => {
    history.push('/');
  };
  

  const render = (): JSX.Element => {
    return (
      <div className={props.type}>
        <div className="notification flex-column-center">
          <div className="notification__content flex-column-center">
            <img src={infoIcon} alt="question mark" />
            <h1 className="notification__header">{localization.PasswordReset[language]}</h1>
            {notificationText}
            <Button onClick={handleButtonClick} className='return_to_login_page_button'>
              {localization.returnToLoginPage[language]}
            </Button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Layout render={render} />
  );
};

export default NotificationPage;
