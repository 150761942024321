import React, { useEffect } from 'react';
import Layout from '../../shared/Layout/Layout';
import Input from '../../shared/Input/Input';
import useInput from '../../../hooks/useInput';
import Button from '../../shared/Button/Button';
import './LogIn.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../index';
import localization from '../../../localization/localization';
import { useHistory } from 'react-router-dom';
import { logIn, setUserLogIn } from '../../../store/actions/userActions';
import { storePatientData } from '../../../store/actions/patientActions';
import { getSessionDataAjax } from '../../../helpers/httpService';
import checkIcon from '../../../imgs/check-icon.png';

const LogIn = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  // custom hooks for inputs
  const emailControl = useInput({});
  const passwordControl = useInput({});

  const language = useSelector((state: RootState) => state.localization.language);
  const loggedIn = useSelector((state: RootState) => state.user.logIn);

  // redirect if already logged in
  useEffect(() => {
    if (loggedIn) {
      history.push('/results');
    } else {
      getSessionDataAjax()
        .then(res => {
          console.log(res);
          dispatch(storePatientData(res.data));
          dispatch(setUserLogIn());

          history.push('/results');
        })
        .catch(err => {
          console.log(err.message);
        });
    }
  }, [loggedIn, history, dispatch]);


  const handleLogInClick = (): void => {
    dispatch<any>(logIn(emailControl.value, passwordControl.value, language));
  };

  const handleEnterClick = (event: React.KeyboardEvent<any>) => {
    if (event.keyCode === 13) {
      handleLogInClick();
    }
  };

  const handleForgotPasswordClick = (): void => {
    history.push('/forgot-password');
  };

  const render = (): JSX.Element => {

    return (
      <div className="flex-row log-in__info-wrapper">
        <div className="log-in__info-container flex-column">
          <span className="log-in__info-header">{localization.exploreResults[language]}</span>
          <div className="log-in__info-text-conteiner flex-row-center">
            <div>
              <img src={checkIcon} alt="check" />
            </div>
           
            <span className="log-in__info-text">{localization.learMoreBrain[language]}</span>
          </div>

          <div className="log-in__info-text-conteiner flex-row-center">
            <div>
              <img src={checkIcon} alt="check" />
            </div>
            <span  className="log-in__info-text">{localization.sleepAndNutritionInfo[language]}</span>
          </div>

          <div className="log-in__info-text-conteiner flex-row-center">
            <div>
              <img src={checkIcon} alt="check" />
            </div>
            <span  className="log-in__info-text">{localization.cognitiveInfo[language]}</span>
          </div>

        </div>
        <div className="log-in flex-column-center">
        <div className="log-in__content flex-column-center">
          <Input
            label={localization.email[language]}
            control={emailControl}
            className='log-in__email-input'
          />

          <Input
            type='password'
            label={localization.password[language]}
            control={passwordControl}
            onKeyUp={handleEnterClick}
            className='log-in__password-input'
          />


          <Button onClick={handleLogInClick} type='log-in' className="log-in__log-in-button">
            {localization.logIn[language]}
          </Button>

          <span className="log-in__forgot-pass" onClick={handleForgotPasswordClick}>{localization.forgotPassword[language]}</span>
        </div>
      </div>
        
      </div>
      
    );
  };
  return (
    <Layout render={render} />
  );
};

export default LogIn;
