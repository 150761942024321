import React from 'react';
import './ResultTable.scss';
import downloadIcon from '../../../imgs/download-icon.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../..';
import localization from '../../../localization/localization';
import { dateToLongString } from '../../../helpers/dateHelpers';
import { safeLocalization } from '../../../helpers/localizationHelper';

export interface ResultTableRow {
  handleDownloadClick: (studyId: string, reportType: string) => void;
  reportType: string;
  date: Date;
  studyId: string;
}

interface ResultTableProps {
  year: string;
  rows: ResultTableRow[];
}

const ResultTable = (props: ResultTableProps) => {
  const language = useSelector((state: RootState) => state.localization.language);
  
  const tableRows = props.rows.map(row => {
    return(
      <div className="result-table__row flex-row-center" key={row.studyId + row.reportType}>
        <div className="flex-row result-table__text-container">
          <span className={"result-table__row-date result-table__row-date--" + row.studyId + '-' +  row.reportType}>
            {dateToLongString(row.date)}
          </span>
          <span>{safeLocalization(row.reportType, language, true)}</span>
        </div>

        <div 
          className={"result-table__download-container flex-row-center result-table__download-container-" + row.studyId + '-' +  row.reportType}
          onClick={() => row.handleDownloadClick(row.studyId, row.reportType)}
        >
          <span className="result-table__download-text">{localization.download[language]}</span>
          <img src={downloadIcon} className="result-table__download-icon" alt="download" />
        </div>      
      </div>
    );
  })

  return (
    <div className="result-table__wrapper"> 
      <span className="result-table__year">{props.year}</span>
      <div className="result-table">
        <div className="result-table__row result-table__row--header flex-row-center">
          <div className="flex-row result-table__text-container">
            <span className="result-table__row-date">{localization.date[language]}</span>
            <span>{localization.report[language]}</span>
          </div>        
        </div>
        {tableRows}
      </div>
    </div>
  );
}

export default ResultTable;
