import { LocalizationState, LocalizationActions } from '../types';
import * as actionTypes from '../actions/actionTypes';
import * as languages from '../../localization/languages';

const initialState: LocalizationState = {
  language: languages.EN
};

export const localizationReducer = ( state = initialState, action: LocalizationActions): LocalizationState => {
  switch (action.type) {
  case actionTypes.CHANGE_LANGUAGE:

    return {
      ...state,
      language: action.payload
    };
  default:
    return state;
  }
};
