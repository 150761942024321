import * as actionTypes from './actionTypes';
import { PatientAjax } from '../../helpers/httpService';
import { PatientStoreData } from '../types';

export const storePatientData = (patient: PatientAjax): PatientStoreData => {

  console.log(patient);
  return ({
    type: actionTypes.STORE_PATIENT,
    payload: patient
  });
};

export const cleanPatientData = () => {
  return ({
    type: actionTypes.CLEAN_PATIENT
  });
};

