import { PatientState, PatientActions } from '../types';
import * as actionTypes from '../actions/actionTypes';


const initialState: PatientState = {
  account: null
};

export const patientReducer = ( state = initialState, action: PatientActions): PatientState => {
  switch (action.type) {

  case actionTypes.STORE_PATIENT:

    return {
      account: action.payload
    };

  case actionTypes.CLEAN_PATIENT:

    return initialState;

  default:
    return state;
  }
};
