import * as actionTypes from './actionTypes';
import { loginAjax, logOutAjax } from '../../helpers/httpService';
import { AppThunk, UserLogIn, UserLogOut } from '../types';
import localization from '../../localization/localization';
import { hideMessage, showMessage } from './messageBarActions';
import { cleanPatientData, storePatientData } from './patientActions';
import { AllLanguages } from '../../localization/languages';
import history from '../../helpers/history';

export const logIn = (email: string, password: string, language: AllLanguages): AppThunk => async dispatch => {

  try {
    const patient = await loginAjax(email, password);
    const patientData = patient.data;
    dispatch(storePatientData(patientData));
    dispatch(hideMessage());

    return dispatch(
      setUserLogIn()
    );

  } catch (ex) {
    dispatch(showMessage(localization.failedToLogIn[language]));

    const dispatchAction: UserLogOut = {
      type: actionTypes.LOG_OUT
    };

    return dispatch(
      dispatchAction
    );
  }
};

export const logOut = (language: AllLanguages): AppThunk => async dispatch => {

  try {
    await logOutAjax();
    dispatch(cleanPatientData());
    dispatch(cleanStore());
    history.push('/');

  } catch (ex) {
    dispatch(showMessage(localization.failedToLogOut[language]));
  }

};

export const setUserLogIn = () => {

  const dispatchAction: UserLogIn = {
    type: actionTypes.LOG_IN
  };

  return dispatchAction;
};

export const setUserLogOut = () => {
  const dispatchAction = {
    type: actionTypes.LOG_OUT
  };

  return dispatchAction;

};

export const cleanStore = () : AppThunk => async dispatch => {

  dispatch(setUserLogOut());
};

// exception should alwways be AJAX exception which has response atribute,
// but if back-end is broken it can be other exception
export const checkAuth = (exception: any, language: AllLanguages): AppThunk => async dispatch => {

  if (exception.response) {
    if (exception.response.status === 401) {
      dispatch(cleanStore());
    }
  } else {
    dispatch(showMessage(localization.unknownError[language]));
  }
};

