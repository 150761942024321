import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import { 
  isNumber,
  isValidEmail, 
  isValidFloatNumber, 
  isValidMaxLength, 
  isValidNewPassword, 
  isValidNumericRange, 
  isValidPassword 
} from '../helpers/InputHelpers';
import localization from '../localization/localization';


export type InputValidation = 'password' | 'email' | 'number' | 'required' | 'newPassword' | 
  'year' | 'month' | 'day' | 'numericRange' | 'maxLength' | 'floatNumber' | 'numericFloatRange';

interface UseInputProps {
  initialValue?: string | null;
  validationPayload?: string;
  validation?: InputValidation
}

export type UseInput = ReturnType<typeof useInput>;

const useInput = (props: UseInputProps) => {
  const [value, setValue] = useState(props.initialValue || '');
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const [validation, setValidation] = useState(props.validation );
  const [validationPayload, setValidationPayload] = useState(props.validationPayload);

  const isValidationInUse = props.validation ? true : false;

  const language = useSelector((state: RootState) => state.localization.language);

  const onChange = (e: React.ChangeEvent<any>) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    if (props.validation) {
      validate();
    }
  };

  const validate = (givenValue: string | null = null): boolean => {
    let checkedValue = value;
    if(givenValue) {
      checkedValue = givenValue;
    }
    // if field has validation it is required i.e. not empty
    if (checkedValue === '') {
      setError(localization.fieldIsRequired[language]);
      return false;
    } else {
      setError('');
    };

    let valid = true;
    switch (validation) {
    case 'number':
      if (isNumber(checkedValue)) {
        setError('');
      } else {
        setError(localization.fieldMustBeNumber[language]);
        valid = false;
      }
      break;
    case 'email':
      if (isValidEmail(checkedValue)) {
        setError('');
      } else {
        setError(localization.emailError[language]);
        valid = false;
      }
      break;
    case 'password':
      if (isValidPassword(checkedValue)) {
        setError('');
      } else {
        setError(localization.passwordError[language]);
        valid = false;
      }
      break;
    case 'newPassword':
      if (isValidNewPassword(checkedValue)) {
        setError('');
      } else {
        setError(localization.passwordError[language]);
        valid = false;
      }
      break;
    case 'numericRange':
      if(isValidNumericRange(checkedValue, validationPayload)) {
        setError('');
      } else {
        setError(localization.invalidNumericRange[language] + ' ' + validationPayload);
        valid = false;
      }
      break;
    case 'maxLength':
      if (isValidMaxLength(checkedValue, validationPayload)) {
        setError('');
      } else {
        setError(localization.invalidValidMaxLength[language]);
        valid = false;
      }
      break;
    case 'floatNumber':
      if (isValidFloatNumber(checkedValue)) {
        setError('');
      } else {
        setError(localization.fieldMustBeNumber[language]);
        valid = false;
      }
      break;
    case 'numericFloatRange':
      if (isValidNumericRange(checkedValue, validationPayload, 'float')) {
        setError('');
      } else {
        setError(localization.invalidNumericRange[language] + ' ' + validationPayload);
        valid = false;
      }
      break;

    }
    
    return valid;
  };

  return {
    validate,
    value,
    disabled,
    error,
    setError,
    onChange,
    handleBlur,
    setValue,
    setDisabled,
    isValidationInUse,
    setValidationPayload
  };

};
export default useInput;

