import React from 'react';
import Layout from '../../shared/Layout/Layout';
import './Account.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../index';
import localization from '../../../localization/localization';
import { UpdatePatientAccountBodyAjax, modifyPatientAccountAjax } from '../../../helpers/httpService';
import { showMessage } from '../../../store/actions/messageBarActions';
import { formatDateFromISO, formatDateToISO } from '../../../helpers/dateHelpers';
import AccountItem, { AccountItemType } from '../../shared/AccountItem/AccountItem';
import { storePatientData } from '../../../store/actions/patientActions';

const Account = () => {
  const dispatch = useDispatch();
  const language = useSelector((state: RootState) => state.localization.language);
  const account = useSelector((state: RootState) => state.patient.account);

  const createEmptypostBody = () => {
    const postBody = {
      firstName: account?.firstName ?? '',
      lastName: account?.lastName ?? '',
      birthDay: account?.birthDay ?? '',
      currentEmail: account ? account.email : '',
      newEmail: null as string | null,
      currentPassword:  null as string | null,
      newPassword: null as string | null,
      patientId: account ? account.patientId : ''
    };

    return postBody;
  }

  const modifypatientAccount = async (postBody: UpdatePatientAccountBodyAjax) => {
    try {
      await modifyPatientAccountAjax(postBody);

      const patientState = {
        firstName: postBody.firstName || '',
        lastName: postBody.lastName || '',
        birthDay: postBody.birthDay || '',
        email:  postBody.newEmail ?? postBody.currentEmail,
        patientId: account ? account.patientId : ''
      };
      
      dispatch(storePatientData(patientState));
      dispatch(showMessage(localization.personalInfoUpdated[language], 'info'));
    } catch(ex: any) {
      dispatch(showMessage(localization.failedToUpdatePersonalInfo[language]));
    }
  }

  const handleNameClick = async (firstName: string, lastName: string, empty: string) => {
    const postBody = createEmptypostBody();
    postBody.firstName = firstName;
    postBody.lastName = lastName;
    modifypatientAccount(postBody);
  }

  const handleEmailClick = async (email: string, empty1: string, empty2: string) => {
    const postBody = createEmptypostBody();
    postBody.newEmail = email;
    modifypatientAccount(postBody);
  }

  const handleBirthDayClick = async (day: string, month: string, year: string) => {
    const postBody = createEmptypostBody();
    const birthDate = day + '.' + month + '.' + year;
    const birthDateISO = formatDateToISO(birthDate);
    postBody.birthDay = birthDateISO || '';
    modifypatientAccount(postBody);
  }

  const handlePasswordClick = async (currentPassword: string, newPassword: string, empty: string) => {
    const postBody = createEmptypostBody();
    postBody.currentPassword = currentPassword;
    postBody.newPassword = newPassword;
    modifypatientAccount(postBody);
  }

  const render = (): JSX.Element => {

    const firstName = account?.firstName ?? '';
    const lastName = account?.lastName ?? '';
    const birthDay = account?.birthDay ?? '';
    let birthDayDay = '';
    let birthDayMonth = '';
    let birthDayYear = ''
    const email = account?.email ?? '';
    const birthDaySplit = birthDay.split('T');
    if (birthDaySplit.length > 0) {
      const birthDayDate = birthDaySplit[0];
      const  birthDayDateSplit = birthDayDate.split('-');
      if(birthDayDateSplit.length === 3) {
        birthDayDay = birthDayDateSplit[2];
        birthDayMonth = birthDayDateSplit[1];
        birthDayYear = birthDayDateSplit[0];
      }
    }
    
    return (
      <div className='account flex-column-center'>
        <div className='account__content flex-column'>
          <h1 className='account__header'>{localization.personalInfo[language]}</h1>

          <AccountItem
            header={localization.legalName[language]}
            value={firstName + ' ' + lastName}
            inputvalue1={firstName}
            inputValue2={lastName}
            handleEditClick={handleNameClick}
            type='name'
          />

          <AccountItem
            header={localization.birthday[language]}
            value={formatDateFromISO(birthDay)}
            inputvalue1={birthDayDay}
            inputValue2={birthDayMonth}
            inputValue3={birthDayYear}       
            handleEditClick={handleBirthDayClick}
            type='birthDay'
          />

          <AccountItem
            header={localization.email[language]}
            value={email}
            inputvalue1={email}
            inputValue2={''}          
            handleEditClick={handleEmailClick}
            type='email'
          />

          <AccountItem
            header={localization.password[language]}
            value={'*******'}
            inputvalue1={''}
            inputValue2={''}
            inputValue3={''}
            handleEditClick={handlePasswordClick}
            type='password'
          />
        
        </div>
      </div>
    );
  };
  return (
    <Layout render={render} />
  );
};

export default Account;
