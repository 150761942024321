import React, { useEffect, useState } from 'react';
import Layout from '../../shared/Layout/Layout';
import Input from '../../shared/Input/Input';
import useInput from '../../../hooks/useInput';
import Button from '../../shared/Button/Button';
import './ResetPassword.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../index';
import localization from '../../../localization/localization';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { hideMessage, showMessage } from '../../../store/actions/messageBarActions';
import { checkPasswordResetKeyAjax, resetPatientPasswordAjax } from '../../../helpers/httpService';
import blocksIcon from '../../../imgs/blocks-icon.png';

interface ResetPasswordParams {
  resetkey: string;
}

const ResetPassword = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const language = useSelector((state: RootState) => state.localization.language);

  const { resetkey } = useParams<ResetPasswordParams>();
  const passwordControl = useInput({ validation: 'newPassword' });
  const retypePasswordControl = useInput({ validation: 'newPassword' });

  useEffect(() => {
    checkPasswordResetKeyAjax(resetkey)
      .catch(err => {
        history.push('/invalid-pass-reset-link');
      });
  }, [history, resetkey]);

  const handleEnterClick = (event: React.KeyboardEvent<any>) => {
    if (event.keyCode === 13) {
      handleChangePasswordClick();
    }
  };

  const handleChangePasswordClick = (): void => {
    // check password is retyped correctly
    let valid = true;
    valid = passwordControl.validate() ? valid : false;
    valid = handleRepeatPasswordBlur() ? valid :false;

    if (valid) {
      resetPatientPasswordAjax(passwordControl.value, resetkey)
        .then(res => {
          console.log(res);
          dispatch(hideMessage())
          history.push('/pass-has-been-reset');
        })
        .catch(err => {
          console.log(err.message);
          dispatch(showMessage(localization.failedToChangePassword[language]));
        });
    }
    else {
      dispatch(showMessage(localization.someFielsAreInvalid[language]));
    }
  };

  const handleRepeatPasswordBlur = (): boolean => {
    if (passwordControl.value !== retypePasswordControl.value) {
      retypePasswordControl.setError(localization.passwordsDontMatch[language]);
      return false;
    } else {
      return retypePasswordControl.validate();
    }
  };

  const render = (): JSX.Element => {

    return (
      <div className="reset-pass">
        <div className="reset-pass__content flex-column-center">
          <img src={blocksIcon} alt="blocks" />
          <h2 className="reset-pass__header">{localization.GiveNewPassword[language]}</h2>

          <Input
            label={localization.password[language]}
            control={passwordControl}
            type='password'
            className="reset-pass__password-input"
            onKeyUp={handleEnterClick}
          />

          <Input
            label={localization.repeatPassword[language]}
            control={retypePasswordControl}
            type='password'
            className="reset-pass__repeated-password-input"
            onKeyUp={handleEnterClick}
            onBlur={handleRepeatPasswordBlur}
          />

          <Button onClick={handleChangePasswordClick} type='full-width' className="reset-pass__reset-password-button">
            {localization.changePassword[language]}
          </Button>

        </div>
      </div>
    );
  };
  return (
    <Layout render={render}/>
  );
};

export default ResetPassword;
