import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { localizationReducer } from './store/reducers/localizationReducer';
import { userReducer } from './store/reducers/userReducer';
import { messageBarReducer } from './store/reducers/messageBarReducer';
import { createMuiTheme } from '@mui/material/styles';
import blue from '@mui/material/colors/blue';
import brown from '@mui/material/colors/brown';
import { ThemeProvider } from '@mui/material/styles';
import { patientReducer } from './store/reducers/patientReducer';

// theme for Material UI components
const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: brown
  }
});

// add Redux dev tools if running in dev mode
// For now it is added also to Oracle cloud, remove from production
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  localization: localizationReducer,
  user: userReducer,
  patient: patientReducer,
  messageBar: messageBarReducer
});

// needed in useSelector hooks
export type RootState = ReturnType<typeof rootReducer>

// add thunk and Redux dev tools to store
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
