// localization
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

// user
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';

// patient
export const STORE_PATIENT = 'STORE_PATIENT';
export const CLEAN_PATIENT = 'CLEAN_PATIENT';

// snackbar
export const SHOW_MESSAGE_BAR = 'SHOW_MESSAGE_BAR';
export const HIDE_MESSAGE_BAR = 'HIDE_MESSAGE_BAR';
