import React, { useState } from 'react';
import './AccountItem.scss';
import editIcon from '../../../imgs/pencil-icon.png';
import crossIcon from '../../../imgs/cross-black-icon.png'
import Input from '../Input/Input';
import useInput, { InputValidation } from '../../../hooks/useInput';
import Button from '../Button/Button';
import localization from '../../../localization/localization';
import { useSelector } from 'react-redux';
import { RootState } from '../../..';

export type AccountItemType = 'name' | 'birthDay' | 'email' | 'password';

interface AccountItemProps {
  header: string;
  value: string;
  inputvalue1: string;
  inputValue2?: string;
  inputValue3?: string;
  handleEditClick: (input1: string, input2: string, input3: string) => void
  type: AccountItemType;
}

const AccountItem = (props: AccountItemProps) => {
  const language = useSelector((state: RootState) => state.localization.language);
  const [editMode, setEditMode] = useState(false);

  const rightContainerText = editMode 
    ? localization.close[language]
    : localization.edit[language];

  const rightContainerImg = editMode
    ? crossIcon
    : editIcon;

  const editIconClass =  editMode
    ? 'account-item__edit-icon account-item__edit-icon--large'
    : 'account-item__edit-icon';

  const inputContainerClass = props.type === 'password'
    ? 'account-item__input-container account-item__input-container--column'
    : 'account-item__input-container';

  const inputType = props.type === 'password' ? 'password' : 'text';

  let inputHeader1 = '';
  let inputHeader2 = '';
  let inputHeader3 = '';
  let inputValidation1: InputValidation = 'required';
  let inputValidation2: InputValidation = 'required';
  let inputValidation3: InputValidation = 'required';
  let inputValidationPayload1 = '';
  let inputValidationPayload2 = '';
  let inputValidationPayload3 = '';

  switch(props.type){
    case 'name':
      inputHeader1 = localization.firstName[language];
      inputHeader2 = localization.lastName[language];
      break;
    case 'birthDay':
      inputHeader1 = localization.day[language];
      inputHeader2 = localization.month[language];
      inputHeader3 = localization.year[language];
      inputValidation1 = 'numericRange';
      inputValidation2 = 'numericRange';
      inputValidation3 = 'numericRange';
      inputValidationPayload1 = '1-31';
      inputValidationPayload2 = '1-12';
      const maxYear = new Date().getFullYear().toString();
      inputValidationPayload3 = '1900-' + maxYear;
      break;
    case 'email':
      inputHeader1 = localization.email[language];
      inputHeader2 = localization.repeatEmail[language];
      inputValidation1 = 'email';
      inputValidation2 = 'email';
      break;
    case 'password':
      inputHeader1 = localization.currentPassword[language];
      inputHeader2 = localization.newPassword[language];
      inputHeader3 = localization.repeatNewPassword[language];
      inputValidation1 = 'newPassword';
      inputValidation2 = 'newPassword';
      inputValidation3 = 'newPassword';
      break;
  }

  const firstInputparams = {
    initialValue: props.inputvalue1,
    validation: inputValidation1,
    validationPayload: inputValidationPayload1
  };

  const secondInputParams = {
    initialValue: props.inputValue2,
    validation: inputValidation2,
    validationPayload: inputValidationPayload2
  };

  const thirdInputParams = {
    initialValue: props.inputValue3,
    validation: inputValidation3,
    validationPayload: inputValidationPayload3
  };

  const firstInputControl = useInput(firstInputparams);
  const secondInputControl = useInput(secondInputParams);
  const thidInputControl = useInput(thirdInputParams);

  const handleSaveButtonClick = () => {
    let valid = true;
    valid = firstInputControl.validate() ? valid : false;
    valid = secondInputControl.validate() ? valid : false;
    switch(props.type) {
      case 'birthDay':
        valid = thidInputControl.validate() ? valid : false;
        break;
      case 'password':
        valid = thidInputControl.validate() ? valid : false;
        if(secondInputControl.value !== thidInputControl.value) {
          secondInputControl.setError(localization.passwordsDontMatch[language]);
          thidInputControl.setError(localization.passwordsDontMatch[language]);
          valid = false;
        }
        break;
      case 'email':
        if(firstInputControl.value !== secondInputControl.value) {
          firstInputControl.setError(localization.emailsDontMatch[language]);
          secondInputControl.setError(localization.emailsDontMatch[language]);      
          valid = false;
        }
        break;
    }

    if(!valid) {
      return;
    }

    setEditMode(!editMode);
    props.handleEditClick(firstInputControl.value, secondInputControl.value, thidInputControl.value);
  }

  const handleEditClick = () => {
    setEditMode(!editMode);
  }

  return(
    <div className="account-item flex-column">
      <div className="account-item__header-container flex-row">
        <div className="account-item__left-container flex-column">
          <span className="account-item__left-header">{props.header}</span>
          {!editMode && <span className={"account-item__header-" + props.type}>{props.value}</span>}         
        </div>

        <div className="account-item__right-container">
          <div 
            className={"account-item__edit-icon-container flex-row-center account-item__edit-icon-container--" + props.type} 
            onClick={handleEditClick}
          >
            <img src={rightContainerImg} className={editIconClass} alt="edit" />
            <span className="account-item__edit-text">{rightContainerText}</span>
          </div>  
        </div>
      </div>

      { editMode && 
        (
          <div className="account-item__edit-container">
            <div className={inputContainerClass + " flex-row"}>
              <Input
                control={firstInputControl}
                className="account-item__input-1"
                label={inputHeader1}
                type={inputType}
              />
              <span className='account-item__margin'></span>
              {props.inputValue2 !== undefined && 
                <Input 
                  control={secondInputControl} 
                  className="account-item__input-2" 
                  label={inputHeader2} 
                  type={inputType}
                />
              }
              {props.inputValue3 !== undefined && <span className="account-item__margin"></span>}
              {props.inputValue3 !== undefined && 
                <Input 
                  control={thidInputControl} 
                  className="account-item__input-3" 
                  label={inputHeader3} 
                  type={inputType}
                />
              }           
            </div>
            <Button 
              onClick={handleSaveButtonClick} 
              className={"account-item__save-button-" + props.type}
            >
              {localization.save[language]}
            </Button>
          </div>
        )
      }      
    </div>
  );

}

export default AccountItem;