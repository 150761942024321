import React from 'react';
import './Button.scss';

interface ButtonProps {
  children: string;
  onClick: () => void;
  styles?: { [key: string]: string};
  type?: string;
  disabled?: boolean;
  className?: string;
}

const Button = (props: ButtonProps) => {

  let buttonClass = 'button';

  if (props.type === 'previous') {
    buttonClass = 'button button--previous';
  } else if (props.type === 'log-in') {
    buttonClass = 'button button--log-in';
  } else if(props.type === 'full-width') {
    buttonClass = 'button button--full-width';
  }

  if (props.disabled) {
    buttonClass = 'button button--disabled';
  }

  return (
    <button
      disabled={props.disabled}
      className={buttonClass + ' ' + props.className}
      style={props.styles}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
