import React from 'react';
import './App.scss';
import { Switch, Route } from 'react-router-dom';
import LogIn from './components/pages/LogIn/LogIn';
import PrivateRouter from './helpers/PrivateRouter';
import { Router } from 'react-router';
import history from './helpers/history';
import CheckAuth from './helpers/CheckAuth';
import Account from './components/pages/Account/Account';
import Results from './components/pages/Results/Results';
import ForgotPassword from './components/pages/ForgotPassword/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword/ResetPassword';
import NotificationPage from './components/pages/NotificationPage/NotificationPage';

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={LogIn}/>
        <Route exact path="/forgot-password" component={ForgotPassword}/>
        <Route exact path="/reset-pass/:resetkey" component={ResetPassword}/>
        <Route exact path="/check-auth/:path" component={CheckAuth}/>
        <PrivateRouter path='/account' component={Account}/>
        <PrivateRouter path='/results' component={Results}/>
        <Route
          exact
          path="/invalid-pass-reset-link"
          component={() => <NotificationPage type="invalid-pass-reset-link"/>}
        />
        <Route
          exact
          path="/check-email-for-pass-reset/:email"
          component={() => <NotificationPage type="check-email-for-pass-reset"/>}
        />
        <Route
          exact
          path="/pass-has-been-reset"
          component={() => <NotificationPage type="pass-has-been-reset"/>}
        />
      </Switch>
    </Router>
  );
}

export default App;
