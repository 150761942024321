import React from 'react';
import './InfoModal.scss';
import Button from '../Button/Button';
import localization from '../../../localization/localization';
import { useSelector } from 'react-redux';
import { RootState } from '../../..';

interface InfoModalProps {
  type: 'medicalAdvice' | 'networksInfo' | 'loading';
  handleContinueClick: () => void;
  handleCloseClick: () => void;
}

const InfoModal = (props: InfoModalProps) => {
  const language = useSelector((state: RootState) => state.localization.language);
  let modalContent = (
    <div className='info-modal__content-container'>
      <p>{localization.medicalAdviceModalPart1[language]}</p>
      <p><b>{localization.medicalAdviceModalPart2[language]}</b></p>
      <p>{localization.medicalAdviceModalPart3[language]}</p>
    </div>
  );

  if(props.type === 'networksInfo') {
    modalContent = (
      <div className='info-modal__content-container'>
        <p>{localization.networksInfoPart1[language]}</p>
        <p>{localization.networksInfoPart2[language]}</p>
        <p><b>{localization.networksInfoPart3[language]}</b></p>
        <p>{localization.networksInfoPart4[language]}</p>
      </div>
    );
  } else if(props.type === 'loading') {
    modalContent = (
      <div className='info-modal__content-container'>
        <p>{localization.loadingreport[language]}</p>
      </div>
    );
  }

  return (
    <div className='info-modal flex-column-center'>
      <div className='info-modal__modal flex-column-center'>
        <h1>{localization.info[language]}</h1>
        {modalContent}
        { props.type !== 'loading' && 
          <div className='info-modal__button-container flex-column'>
            <Button type='full-width' onClick={() => props.handleContinueClick()} className='info-modal__accept-button'>{localization.acceptAndContinue[language]}</Button>
            <Button type='full-width' onClick={() => props.handleCloseClick()} className='info-modal__close-button'>{localization.close[language]}</Button>
          </div>
        }
        

      </div>   
    </div>
  );
}

export default InfoModal;