import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../index';

interface PrivateRouterProps {
  component: React.FC<{}>,
  path: string,
  routerParams?: string;
}

const PrivateRouter = ({ component: Component, path, routerParams }: PrivateRouterProps) => {

  const loggedIn = useSelector((state: RootState) => state.user.logIn);

  const renderContent = () => {

    if (loggedIn) {

      if (routerParams !== null) {
        // @ts-expect-error:
        return < Component >{routerParams} </ Component>;
      } else {
        return <Component/>;
      }

    } else {
      return <Redirect to={'/check-auth' + path}/>;
    }
  };
  return (
    <Route path={path} render={renderContent} exact={true} />
  );
};

export default PrivateRouter;
