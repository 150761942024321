import React, { useEffect } from 'react';
import './MessageBar.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../..';
import { hideMessage } from '../../../store/actions/messageBarActions';
import crossIcon from '../../../imgs/cross-icon.png';


const MessageBar = () => {
  const messageState = useSelector((state: RootState) => state.messageBar);
  const dispatch = useDispatch();

  const messageBarClass = messageState.messageType === 'info'
    ? 'message-bar message-bar--info '
    : 'message-bar message-bar--error ';

  const messageBarVisible = messageState.message !== ''
    ? 'message-bar--visible'
    : '';

  return (
    <div className={"flex-row-center " + messageBarClass + messageBarVisible}>
      <div>
        <span className="message-bar__text">{messageState.message}</span>
      </div>
      
      <img 
        className="message-bar__cross-icon"
        src={crossIcon}
        alt="cross"
        onClick={() => {dispatch(hideMessage())}}
      />
    </div>
  );
};

export default MessageBar;
