import { UserState, UserActions } from '../types';
import * as actionTypes from '../actions/actionTypes';

const initialState: UserState = {
  logIn: false
};

export const userReducer = ( state = initialState, action: UserActions): UserState => {
  switch (action.type) {
  case actionTypes.LOG_IN:

    return {
      logIn: true
    };

  case actionTypes.LOG_OUT:

    return {
      logIn: false
    };

  default:
    return state;
  }
};
