import * as actionTypes from './actionTypes';
import { MessageBarShowMessage, MessageBarHideMessage, MessageType } from '../types';

export const showMessage = (message: string, messageType: MessageType = 'error'): MessageBarShowMessage => {
  const dispatchAction: MessageBarShowMessage = {
    type: actionTypes.SHOW_MESSAGE_BAR,
    payload: {
      message,
      messageType
    }
  };

  return dispatchAction;
};

export const hideMessage = (): MessageBarHideMessage => {
  const dispatchAction: MessageBarHideMessage = {
    type: actionTypes.HIDE_MESSAGE_BAR
  };
  return dispatchAction;
};
