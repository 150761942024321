import { AllLanguages } from "../localization/languages";
import localization from "../localization/localization";

export const safeLocalization = (text: string, language: AllLanguages, allowRawtext: boolean = false): string => {
  let localizedString = localization.unknown[language]
  if(allowRawtext) {
    localizedString = text
  }

  if (text in localization) {
    const secureLocalization: any = localization
    localizedString = secureLocalization[text][language]
  }
  return localizedString;
}

