import React from 'react';
import Layout from '../../shared/Layout/Layout';
import Input from '../../shared/Input/Input';
import useInput from '../../../hooks/useInput';
import Button from '../../shared/Button/Button';
import './ForgotPassword.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../index';
import localization from '../../../localization/localization';
import { useHistory } from 'react-router-dom';
import { sendPatientPasswordResetEmail } from '../../../helpers/httpService';
import { showMessage } from '../../../store/actions/messageBarActions';
import questionmarkIcon from '../../../imgs/question-icon.png';


const ForgotPassword = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  // custom hooks for inputs
  const emailControl = useInput({ validation: 'email' });

  const language = useSelector((state: RootState) => state.localization.language);

  const handleSendPasswordResetEmailClick = (): void => {
    // check input email is not empty
    if (emailControl.value !== '') {
      if (!emailControl.validate()) {
        dispatch(showMessage(localization.invalidEmailFormat[language]));
        return;
      }
      sendPatientPasswordResetEmail(emailControl.value)
        .then(res => {
          console.log(res);
          history.push('/check-email-for-pass-reset/' + emailControl.value);
        })
        .catch(err => {
          console.log(err.message);
        });
    }
  };

  const handleEnterClick = (event: React.KeyboardEvent<any>) => {
    if (event.keyCode === 13) {
      handleSendPasswordResetEmailClick();
    }
  };

  const handleLoginClick = (): void => {
    history.push('/');
  };
  const render = (): JSX.Element => {

    return (
      <div className="forgot-password flex-column-center">
        <div className="forgot-password__content flex-column-center">
          <img src={questionmarkIcon} alt="question mark" />
          <h2 className="forgot-password__header">Password reset</h2>
          <span className="forgot-password__info-text">{localization.ForgotPasswordInstruction[language]}</span>

          <div className="forgot-password__email-input-container">

            <Input
              label={localization.email[language]}
              control={emailControl}
              className="forgot-password__email-input"
              onKeyUp={handleEnterClick}
            />

          </div>
          


          <Button
            onClick={handleSendPasswordResetEmailClick}
            type='full-width'
            className="forgot-password__send-password-reset-email-button"
          >
            {localization.SendPasswordResetEmail[language]}
          </Button>
          <span className="forgot-password__login">
            <span>{localization.rememberLogin[language]}</span>
            <span className="forgot-password__login__link" onClick={handleLoginClick}>
              {localization.logIn[language]}
            </span>
          </span>

        </div>
      </div>
    );
  };
  return (
    <Layout render={render} />
  );
};

export default ForgotPassword;
