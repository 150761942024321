import { AllLanguages } from "../localization/languages";
import localization from '../localization/localization';

export const formatDateFromISO = (dateString: string | null): string => {

  // Database initial date
  if (dateString === '0001-01-01T00:00:00' || dateString === null) {
    return '';
  }

  const date = new Date(dateString);
  const formatDate = dateToString(date);
  return formatDate;

};

export const formatDateToISO = (dateString: string): string | null => {

  const dateSplit = dateString.split('.');
  const date = new Date(parseInt(dateSplit[2]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[0]), 10, 10, 10);

  const validationFormat = dateSplit[1]+ '/'+ dateSplit[0] + '/' + dateSplit[2];

  // date is invalid
  if ( !isValidDate(validationFormat)) {
    return null;
  }
  return date.toISOString();
};

export const isValidDate = (dateString: string) => {

  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split('/');
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1800 || year > 2100 || month === 0 || month > 12) {
    return false;
  }
  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export const dateToString = (date: Date) => {
  return (date.getDate().toString() + '.' + (date.getMonth() + 1).toString() + '.' + date.getFullYear());
};

export const dateToLongString = (date: Date) => {
  const month = date.toLocaleString('default', { month: 'short' });
  const dayNumber = date.toLocaleString('default', { day: 'numeric' });
  const weekday = date.toLocaleString('default', { weekday: 'short' });
  const year = date.toLocaleString('default', { year: 'numeric' });

  return weekday + ' ' + month + ' ' + dayNumber + ' ' + year;
}
