import React from 'react';
import './Input.scss';
import { UseInput } from '../../../hooks/useInput';


interface InputProps {
  label?: string;
  control: UseInput;
  type?: string;
  styles?: {[key: string]: string}
  placeholder?: string;
  errorMessage?: string;
  className?: string;
  onKeyUp?: (event: React.KeyboardEvent<any>) => void;
  infoText?: string;
  disabled?: boolean;
  maxLength?: number;
  autoComplete?: string;
  onBlur?: () => boolean;
  noInfoTextHeight?: boolean;
}

const Input = (props: InputProps) => {
  let infoTextClass = props.errorMessage || props.control.error
    ? 'input__info-text input__info-text--error input__info-text--error-' + props.className
    : 'input__info-text input__info-text--' + props.className;

  const inputClass = props.errorMessage || props.control.error
    ? 'input__input input-new__input--error '
    : 'input__input ';

  if (props.noInfoTextHeight) {
    infoTextClass += infoTextClass + ' input__info-text--no-height';
  }

  let infoText = props.infoText;
  if (props.errorMessage) {
    infoText = props.errorMessage
  } else if( props.control.error !== '') {
    infoText =  props.control.error;
  }


  return (
    <div style={props.styles}
      className='input flex-column'
    >
      <span className="input__label">{props.label}</span>


      <input 
        className= {inputClass + props.className}
        value = {props.control.value}
        onChange = {props.control.onChange}
        placeholder={props.placeholder}
        type= {props.type ? props.type : 'text'}
        onKeyUp={props.onKeyUp}
        autoComplete= { props.autoComplete || 'off'}
        disabled={props.control.disabled}
        maxLength={props.maxLength || 100}
        onBlur= {props.onBlur || props.control.handleBlur}
      />
     <span className={infoTextClass}>{infoText}</span>
    </div>
  );
};

export default Input;
