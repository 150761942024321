import axios from 'axios';


axios.defaults.withCredentials = true;

const ApiUrl = process.env.REACT_APP_BACK_END_URL as string;

export const loginAjax = (email: string, password: string) => {

  const postBody = {
    email,
    password
  };

  return axios.post<PatientAjax>(ApiUrl + 'PatientPortal/LoginPatient', postBody);
};

export const logOutAjax = () => {
  return axios.post(ApiUrl + 'PatientPortal/Logout', {});
};

export const getSessionDataAjax = () => {
  return axios.get<PatientAjax>(ApiUrl + 'PatientPortal/GetPatientPortalSessionData', {});
};


export const modifyPatientAccountAjax = (data: UpdatePatientAccountBodyAjax) => {
  return axios.post(ApiUrl + 'PatientPortal/UpdatePatientAccount', data);
};

export const getResultPDFAjax = (studyId: string, reportType: string) => {
  return axios.get(ApiUrl + 'PatientPortal/PDF/' + studyId + '/' + reportType);
};

export const getPatientPDFsAjax = () => {
  return axios.get<ResultInfoAjax[]>(ApiUrl + 'PatientPortal/PatientPDFs');
};

export const sendPatientPasswordResetEmail = (email: string) => {
  const postBody = {
    email
  };
  return axios.post(ApiUrl + 'PatientPortal/SendPatientPasswordResetEmail', postBody);
};

export const checkPasswordResetKeyAjax = (passwordResetKey: string) => {
  const postBody = {
    passwordResetKey
  };

  return axios.post<string>(ApiUrl + 'PatientPortal/CheckPatientPasswordResetKey', postBody);
};

export const resetPatientPasswordAjax = (password: string, passwordResetKey: string) => {
  const postBody = {
    password,
    passwordResetKey
  };
  return axios.post(ApiUrl + 'PatientPortal/ResetPatientPassword', postBody);
};
// Interfaces for API responses

export interface PatientAjax {
  patientId: string;
  firstName: string;
  lastName: string;
  birthDay: string;
  email: string;
}


type stringOrNull = string | null;

export interface UpdatePatientAccountBodyAjax {
  patientId: string;
  firstName: string | null;
  lastName: string | null;
  birthDay: string | null;
  newEmail: string| null;
  currentEmail: string;
  currentPassword: string | null;
  newPassword: string | null;
}

export interface ResultInfoAjax {
  studyId: string;
  date: Date;
  reportTypes: string[];
}
