import React from 'react';
import './Header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../../store/actions/localizationActions';
import { RootState } from '../../../index';
import localization from '../../../localization/localization';
import logo from '../../../imgs/renita-logo.png';
import { logOut } from '../../../store/actions/userActions';
import { AllLanguages } from '../../../localization/languages';
import { useHistory } from 'react-router-dom';

enum ActiveTab {
  Account,
  Results,
  None
};

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector((state: RootState) => state.localization.language);
  const loggedIn = useSelector((state: RootState) => state.user.logIn);

  const handleLanguageChange = (language: AllLanguages): void => {
    dispatch(changeLanguage(language));
  };


  const handleLogOut = ():void => {
    dispatch<any>(logOut(language));
  };

  // changes tab
  const handleTabClick = (address: string): void => {
    history.push(address);
  };

  // UI elements
  let logoutButton = null;

  if (loggedIn) {
    logoutButton = (
      <div className='header__quit-button flex-row-center' onClick={handleLogOut}>
        <span className='header__quit-text'>{localization.logOut[language]}</span>
      </div>
    );
  }

  const currentPage = window.location.href;

  let activeTab = ActiveTab.None;

  if (currentPage.indexOf('account') !== -1) {
    activeTab = ActiveTab.Account;
  } else if (currentPage.indexOf('results') !== -1) {
    activeTab = ActiveTab.Results;
  }


  return (
    <div className='header flex-row-center'>
      <img className='header__logo' src={logo} alt="Renita-logo"/>

      {loggedIn ?
        <div className='header__tabs-container flex-row'>

          <div
            className={'header__tab-account' + ( activeTab === ActiveTab.Account ? ' header__tab-active' : '')}
            onClick={() => {handleTabClick('/account');}}
            tabIndex={0}
          >
            <span className={'header__tab' + ( activeTab === ActiveTab.Account ? ' header__tab-text--active' : '')}>{localization.personalInfo[language]}</span>
          </div>

          <div
            className={'header__tab-results' + (activeTab === ActiveTab.Results ? ' header__tab-active' : '')}
            onClick={() => {handleTabClick('/results');}}
            tabIndex={0}
          >
            <span className={'header__tab' + ( activeTab === ActiveTab.Results ? ' header__tab-text--active' : '')}>{localization.myReports[language]}</span>
          </div>

        </div>
        : null
      }

    {logoutButton}
    </div>
  );
};

export default Header;
